import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import "./index.css"

export default ({ children }) => {
  return (
    <>
      <Helmet>
        <script>
          {`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"343073212", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}
        </script>
      </Helmet>
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(
              sort: { order: ASC, fields: [frontmatter___sortKey] }
            ) {
              edges {
                node {
                  id
                  frontmatter {
                    categoryName
                    categoryID
                    path
                    hidden
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="wrapper">
            <Header siteTitle={"data.site.siteMetadata.title"} data={data} />
            <div className="background-color flex-grow-1">
              <main>{children}</main>
            </div>
            <Footer />
          </div>
        )}
      />
    </>
  )
}
