import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "reactstrap"
import Image from "../image"

function Footer() {
  return (
    <div className="background-color-secondary px-md-5 p-4 pt-5 pb-2">
      <Row>
        <Col xs={12} md={3} className="mt-md-5">
          <Row>
            <Col xs={12}>
              <Link to="/">
                <Image src="logo-min.png" alt="logo" />
              </Link>
              <hr />
              <p className="text-color-grey">
                U Save Services has stayed true to its original mission: bring
                educated buyers and sellers together at the time they are ready
                to do business
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={1} />
        <Col xs={12} md={3} className="mt-5">
          <h4 className="text-light">POPULAR CATEGORIES</h4>
          <hr className="divider" />
          <ul className="footer-list list-unstyled">
            <li>
              <Link
                className="text-decoration-none"
                to="/direct/business-franchises"
              >
                BUSINESS FRANCHISES
              </Link>
            </li>
            <li>
              <Link
                className="text-decoration-none"
                to="/direct/business-phone-systems"
              >
                BUSINESS PHONE SYSTEMS
              </Link>
            </li>
            <li>
              <Link
                className="text-decoration-none"
                to="/direct/business-loans-2"
              >
                BUSINESS LOANS
              </Link>
            </li>
            <li>
              <Link className="text-decoration-none" to="/direct/cloud-backup">
                CLOUD BACKUP
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={1} />
        <Col xs={12} md={3} className="mt-5">
          <h4 className="text-light">CATEGORIES</h4>
          <hr className="divider" />
          <ul className="footer-list list-unstyled">
            <li>
              <Link className="text-decoration-none" to="/about-us">
                ABOUT US
              </Link>
            </li>
            <li>
              <Link className="text-decoration-none" to="/privacy-policy">
                PRIVACY POLICY
              </Link>
            </li>
            <li>
              <Link className="text-decoration-none" to="/terms-and-conditions">
                TERMS AND CONDITIONS
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
      <br />
      <hr className="divider" />
      <div className="text-center text-color-grey">
        Copyright © &nbsp;
        <Link to="/" className="text-color-light creator text-decoration-none">
          U Save Sevices
        </Link>
        &nbsp; All rights reserved | Maintained By &nbsp;
        <a
          href="https://www.linkedin.com/in/chwasifjameel"
          className="text-color-light creator text-decoration-none"
        >
          Wasif Jameel
        </a>
      </div>
    </div>
  )
}

export default Footer
