import React, { useRef, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Link } from "gatsby"

export default function Menu({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const wrapperRef = useRef(null)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside({ target: { id } }) {
        let tempId = id.toLowerCase().includes("@") ? id.split("@")[0] : id
        if (tempId !== "category-links" && tempId !== "category-btn") {
          document.getElementById("dropdown-wrapper").style.display = "none"
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  return (
    <div ref={wrapperRef}>
      <div id="dropdown-wrapper" className={`dropdown-content mt-3`}>
        <Row className="mt-3">
          {edges.map(
            (
              {
                node: {
                  frontmatter: { categoryName, path, hidden },
                },
              },
              index
            ) =>
              !hidden && (
                <Col key={index} xs={6} md={2} className="column">
                  <Link
                    id={`category-links@${index}`}
                    to={path.replace("works", "direct")}
                    className="text-color-dark py-2"
                  >
                    {categoryName}
                  </Link>
                </Col>
              )
          )}
        </Row>
      </div>
    </div>
  )
}
